import React from 'react'
import {graphql} from 'gatsby'
import {Helmet} from 'react-helmet'
import Home from '../components/page/home'
import Layout from '../containers/layout'
import SEO from '../components/seo'

export const query = graphql`
  query sanityHome {
    sanityHome {
      title
      pageTitle
      _rawBody
      hero {
        __typename
        ... on SanityMainImage {
          _key
          _type
          alt
          asset {
            fluid(maxWidth: 1900) {
              ...GatsbySanityImageFluid
            }
          }
        }
        ... on SanitySlideshow {
          _key
          _type
          slides {
            _key
            altText
            slideImage {
              asset {
                fluid(maxWidth: 1900) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
        ... on SanityVideo {
          _key
          _type
          videoType
          videoID
        }
      }
      seoSettings {
        title
        description
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`
const mySiteData = {
  '@context': 'http://www.schema.org',
  '@type': 'HomeAndConstructionBusiness',
  'name': 'Lindgren Landscape',
  'url': 'https://www.lindgrenlandscape.com/',
  'logo': 'https://www.lindgrenlandscape.com/logo.png',
  'image': 'https://www.lindgrenlandscape.com/lindgren.jpg',
  'description': 'Since 1995, Lindgren Landscape has worked to design and build the most innovative and functional outdoor living spaces in Northern Colorado. Together, with landscape designers and architects, our landscape contractors offers premier landscape design, landscape construction, and lawn care to Fort Collins and surrounding areas. Maintaining commercial and high-end residential properties, we specialize in creating unique spaces such as outdoor living rooms, outdoor kitchens, outdoor fire features, water features, paver patios, and walkways, as well as, overall custom landscapes. Our Landscape Management division was created to maintain the unique projects that we create, and we are always open to those looking for landscaping jobs.',
  'telephone': '+1(970) 226-5677',
  'address': {
    '@type': 'PostalAddress',
    'streetAddress': '3825 E County Rd 30',
    'addressLocality': 'Fort Collins',
    'addressRegion': 'CO',
    'postalCode': '80528',
    'addressCountry': 'USA'
  },
  'geo': {
    '@type': 'GeoCoordinates',
    'latitude': '40.464751',
    'longitude': '-105.006071'
  },
  'hasMap': 'https://www.google.com/maps/place/Lindgren+Landscape/@40.464751,-105.006071,15z/data=!4m2!3m1!1s0x0:0x2bea98eec4114484?sa=X&ved=0ahUKEwivo8DE2PbYAhVW1GMKHYnaDhUQ_BIIhAEwCg',
  'openingHours': 'Mo, Tu, We, Th, Fr 08:00-17:00',
  'contactPoint': {
    '@type': 'ContactPoint',
    'telephone': '+1(970) 226-5677',
    'contactType': 'Customer Service'
  },
  'priceRange': '$$$'
}
const PagePage = ({data: {sanityHome: page}}) => (
  <>
    <Helmet>
      <script key='lindgren-site-data' type='application/ld+json'>{JSON.stringify(mySiteData)}</script>
    </Helmet>
    <Layout title={page.title || 'Lindgren Landscape'} hero={page.hero}>
      {page.seoSettings.title && page.seoSettings.description && (
        <SEO title={page.seoSettings.title} description={page.seoSettings.description} openGraphImage={page.seoSettings.openGraphImage ? page.seoSettings.openGraphImage.asset.url : null} />
      )}
      <Home hero={page.hero} title={page.pageTitle} text={page._rawBody} />
    </Layout>
  </>
)

export default PagePage
