import React from 'react'
// import Image from 'gatsby-image'
// https://www.npmjs.com/package/react-responsive-carousel
import PortableText from '../../components/serializers/portableText'
import Container from '../container'
// import {Carousel} from 'react-responsive-carousel'
// import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import './slider.css'

import styles from './home.module.css'

const Home = ({title, text}) => {
  // console.log({hero})
  // const HomeMedia = () => {
  //   if (hero[0].__typename === 'SanityVideo') {
  //     return (
  //       <div className={styles.embedContainer}>
  //         <iframe src={`https://player.vimeo.com/video/${hero[0].videoID}?muted=1&autoplay=1&loop=1&background=1`}
  //           width='640' height='360' frameBorder='0' allowFullScreen allow='autoplay' />
  //       </div>
  //     )
  //   } else if (hero[0].__typename === 'SanitySlideshow') {
  //     return (
  //       <Carousel interval={10000} showThumbs={false} showStatus={false} showIndicators={false} autoPlay stopOnHover={false} useKeyboardArrows dynamicHeight infiniteLoop emulateTouch>
  //         {hero[0].slides.map(slide => (
  //           <div key={slide._key}>
  //             <Image fluid={slide.slideImage.asset.fluid} alt={slide.altText} />
  //           </div>
  //         ))}
  //       </Carousel>
  //     )
  //   } else if (hero[0].__typename === 'SanityMainImage') {
  //     return (

  //       <Carousel interval={10000} showThumbs={false} showStatus={false} showIndicators={false} autoPlay stopOnHover={false} useKeyboardArrows dynamicHeight infiniteLoop emulateTouch>
  //         <div>
  //           <Image fluid={hero[0].asset.fluid} alt={hero[0].altText} />
  //         </div>
  //       </Carousel>
  //     )
  //   }
  // }

  return (
    <>

      <article className={styles.root}>
        <Container>
          <div className={styles.pageTitleWrapper}>
            <h1 className={styles.pageTitle}>{title}</h1>
          </div>
          <div className={styles.mainContent}>
            {text && <PortableText blocks={text} />}
          </div>
        </Container>
      </article>
    </>
  )
}
export default Home
